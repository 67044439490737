body {
  font-family: Helvetica, Arial, Calibri;
}

:root {
  --link-color: #003687;
  --link-hover-color: #05a2e6;
  --link-orange-color: #c75b12;
  --link-orange-hover-color: darkorange;
  --link-black-color: black;
  --link-black-hover-color: grey;
  --link-pink-color: #e75480;
  --link-pink-hover-color: #d1479f;
}

.link,
.link-orange {
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: color 0.3s;
  color: var(--link-color);
}

.link:hover {
  color: var(--link-hover-color);
}

.link-orange {
  color: var(--link-orange-color);
}

.link-orange:hover {
  color: var(--link-orange-hover-color);
}

.link-black {
  color: var(--link-black-color);
}

.link-black:hover {
  color: var(--link-black-hover-color);
}

.link-pink {
  color: var(--link-pink-color);
}

.link-pink:hover {
  color: var(--link-pink-hover-color);
}

a {
  color: #003687;
  text-decoration: none; /* Assuming you want to remove the underline from all links */
}

a:hover {
  position: relative;
  display: inline-block;
  color: #05a2e6;
  text-decoration: none;
  transition: color 0.3s;
}

@media print {
  .print-hide {
    display: none !important;
  }

  .col-sm-3 {
    width: 20%;
  }

  .col-sm-6 {
    width: 60%;
  }

  .col-sm-3 {
    width: 20%;
  }

  .contact-navbar {
    display: flex; /* Ensures flexbox layout */
    flex-direction: row; /* Ensures items are in a row */
    align-items: center; /* Centers items vertically */
    width: 100%; /* Ensures the navbar takes full width */
  }
  .nav-link, .navbar-text {
    display: inline; /* Forces inline display */
    white-space: nowrap; /* Prevents breaking items into new lines */
  }
}