#stardew-border {
    background-color: #f8ca88;
}

.exp-sprite {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.exp-sprite-double-wide {
    width: 60px;
    height: 40px;
    margin-right: 5px;
}

.exp-sprite-triple-wide {
    width: 80px;
    height: 40px;
    margin-right: 5px;
}

.exp-sprite-super-wide {
    width: 120px;
    height: 40px;
    margin-right: 5px;
}

.stardew-border {
    border: 4px solid #5c3a24; /* Dark brown for the wood */
    background-color: #fffbe6; /* Light cream for the interior */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
    padding: 20px;
    margin: 10px;
    position: relative;
}

.stardew-border::before, .stardew-border::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #8c644c; /* Lighter brown for texture */
    pointer-events: none;
}

.stardew-border::before {
    top: -2px;
    left: -2px;
}

.stardew-border::after {
    bottom: -2px;
    right: -2px;
}

.stardew-text {
    font-family: 'Press Start 2P', cursive; /* The font we chose */
    color: #5c3a24; /* A dark brown, resembling wooden signs */
    text-shadow: 2px 2px #8c644c; /* Soft shadow for depth */
    background-color: #fffbe6; /* Light cream background, optional */
    padding: 10px; /* Adds space around the text */
    border: 4px solid #5c3a24; /* Optional: adds a border similar to our previous example */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); /* Optional: adds depth to the border */
}

.stardew-text-no-border {
    font-family: 'Press Start 2P', cursive; /* The font we chose */
    color: #5c3a24; /* A dark brown, resembling wooden signs */
    text-shadow: 2px 2px #8c644c; /* Soft shadow for depth */
    padding: 10px; /* Adds space around the text */
}